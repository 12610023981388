
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import History from "@/components/1crm/1sekolah/product/card-design/history-upload-photo.vue";

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Form,
    History,
  },

  setup() {
    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH);
      setCurrentPageBreadcrumbs("History Upload Data Foto", ["Data"]);
    });
  },
});
